import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.hideLabel ? null : _ctx.generalMethods.translate(_ctx.label),
    for: _ctx.id == null ? _ctx.prop : _ctx.id,
    prop: _ctx.prop,
    rules: _ctx.rules,
    class: _normalizeClass(`${_ctx.elFormItemClassses}`),
    "label-width": "100px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_select, {
        id: _ctx.id == null ? _ctx.prop : _ctx.id,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        "value-key": _ctx.valueKey,
        onChange: _cache[1] || (_cache[1] = (value) => _ctx.emitEvent('change', value)),
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        placeholder: _ctx.generalMethods.translate(_ctx.placeholder),
        size: _ctx.size,
        teleported: false,
        class: _normalizeClass(_ctx.elClasses),
        clearable: _ctx.clearable,
        multiple: _ctx.multiple
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: index,
              label: 
					_ctx.optionEnglishArabicName
						? _ctx.generalMethods.getValueByCurrentLanguage(
								option[(_ctx.optionLabelKey == '' ? '' : _ctx.optionLabelKey + '_') + 'english_name'],
								option[(_ctx.optionLabelKey == '' ? '' : _ctx.optionLabelKey + '_') + 'arabic_name']
							)
						: _ctx.generalMethods.translate(option[_ctx.optionLabelKey == '' ? 'name' : _ctx.optionLabelKey])
				,
              value: option
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["id", "modelValue", "value-key", "disabled", "readonly", "placeholder", "size", "class", "clearable", "multiple"])
    ]),
    _: 1
  }, 8, ["label", "for", "prop", "rules", "class"]))
}