import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
	themeName: "Metronic",
	themeVersion: "8.0.38",
	demo: "demo1",
	main: {
		type: "default",
		primaryColor: "#8f2986",
		logo: {
			dark: "/media/logos/logo-1-dark.svg",
			light: "/media/logos/logo-1.svg",
		},
	},
	illustrations: {
		set: "sketchy-1",
	},
	loader: {
		logo: "/media/logos/logo-1-dark.svg",
		display: true,
	},
	scrollTop: {
		display: true,
	},
	header: {
		display: true,
		menuIcon: "font",
		width: "fluid",
		fixed: {
			desktop: true,
			tabletAndMobile: true,
		},
	},
	toolbar: {
		display: true,
		width: "fluid",
		fixed: {
			desktop: true,
			tabletAndMobile: true,
		},
	},
	aside: {
		display: true,
		theme: "light",
		fixed: true,
		menuIcon: "font",
		minimized: false,
		minimize: true,
		hoverable: true,
	},
	content: {
		width: "fluid",
	},
	footer: {
		width: "fluid",
	},
};

export default config;
