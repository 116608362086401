import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { setToolBarButtons } from "@/core/helpers/PagesCustoms";

import { merchantAppRoutes, merchantGeneralRoutes } from "@/merchant/router";
import { managerAppRoutes, managerGeneralRoutes } from "@/manager/router";
import { websiteRoutes } from "@/website/router";
import { onlineRoutes } from "@/online/router";

const routes: Array<RouteRecordRaw> = [
	...websiteRoutes,

	...managerAppRoutes,

	...merchantAppRoutes,

	...onlineRoutes,

	...merchantGeneralRoutes,

	...managerGeneralRoutes,

	{
		path: "/:language(en|ar)?/404",
		name: "404",
		component: () => import("@/views/Error404.vue"),
		beforeEnter: (to) => {
			const { language } = to.params;
			if (language) {
				window.location.href = process.env.VUE_APP_BASE_PATH + "404.html?language=" + language;
			} else {
				window.location.href = process.env.VUE_APP_BASE_PATH + "404.html";
			}
		},
	},
	{
		path: "/:language(en|ar)?/:catchAll(.*)",
		component: () => import("@/views/Error404.vue"),
		beforeEnter: (to) => {
			const { language } = to.params;
			if (language) {
				window.location.href = process.env.VUE_APP_BASE_PATH + "404.html?language=" + language;
			} else {
				window.location.href = process.env.VUE_APP_BASE_PATH + "404.html";
			}
		},
	},
	{
		path: "/:catchAll(.*)",
		component: () => import("@/views/Error404.vue"),
		beforeEnter: () => {
			window.location.href = process.env.VUE_APP_BASE_PATH + "404.html";
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.onError((error) => {
	if (/ChunkLoadError:.*failed./i.test(error.message)) {
		window.location.reload();
	} else if (/Loading.*chunk.*failed./i.test(error.message)) {
		window.location.reload();
	}
});

router.beforeEach((to) => {
	setToolBarButtons([]);

	if (to.meta != undefined && to.meta != null && (to.meta.type == "app" || to.meta.type == "manager-app" || to.meta.type == "manager-auth")) {
		store.dispatch(Actions.GET_GLOBAL_INFORMATIONS);
	}

	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

export default router;
